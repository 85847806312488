exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-estudios-js": () => import("./../../../src/pages/estudios.js" /* webpackChunkName: "component---src-pages-estudios-js" */),
  "component---src-pages-f-js": () => import("./../../../src/pages/f.js" /* webpackChunkName: "component---src-pages-f-js" */),
  "component---src-pages-f-phonefb-js": () => import("./../../../src/pages/f/phonefb.js" /* webpackChunkName: "component---src-pages-f-phonefb-js" */),
  "component---src-pages-f-thanksfb-js": () => import("./../../../src/pages/f/thanksfb.js" /* webpackChunkName: "component---src-pages-f-thanksfb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-copy-phone-js": () => import("./../../../src/pages/l copy/phone.js" /* webpackChunkName: "component---src-pages-l-copy-phone-js" */),
  "component---src-pages-l-copy-thanks-js": () => import("./../../../src/pages/l copy/thanks.js" /* webpackChunkName: "component---src-pages-l-copy-thanks-js" */),
  "component---src-pages-l-js": () => import("./../../../src/pages/l.js" /* webpackChunkName: "component---src-pages-l-js" */),
  "component---src-pages-l-phone-js": () => import("./../../../src/pages/l/phone.js" /* webpackChunkName: "component---src-pages-l-phone-js" */),
  "component---src-pages-l-thanks-js": () => import("./../../../src/pages/l/thanks.js" /* webpackChunkName: "component---src-pages-l-thanks-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-ref-js": () => import("./../../../src/pages/ref.js" /* webpackChunkName: "component---src-pages-ref-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-trips-123-js": () => import("./../../../src/pages/trips/123.js" /* webpackChunkName: "component---src-pages-trips-123-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-offer-sanity-js": () => import("./../../../src/templates/offerSanity.js" /* webpackChunkName: "component---src-templates-offer-sanity-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-study-js": () => import("./../../../src/templates/study.js" /* webpackChunkName: "component---src-templates-study-js" */),
  "component---src-templates-trip-js": () => import("./../../../src/templates/trip.js" /* webpackChunkName: "component---src-templates-trip-js" */)
}

